import React from 'react';
import { Link } from 'react-router-dom';

function ThankYou() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-purple-900 via-indigo-900 to-blue-900 text-white px-4 py-8">
      <div className="text-center max-w-md w-full">
        <h1 className="text-3xl md:text-4xl font-bold mb-4 md:mb-6">Thank You!</h1>
        <p className="text-lg md:text-xl mb-6 md:mb-8">Your message has been received. I'll be in touch soon!</p>
        <Link
          to="/"
          className="inline-block bg-white text-blue-900 px-6 py-3 rounded-full font-semibold hover:bg-opacity-90 transition-all duration-300 text-sm md:text-base"
        >
          Back to Home
        </Link>
      </div>
    </div>
  );
}

export default ThankYou;
