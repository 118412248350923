import React, { useState, useRef } from 'react';
import { PlayCircle, PauseCircle, User, Book, MessageSquare, Calendar, Building2 } from 'lucide-react';

const SpeakerProfile = () => {
  const [activeSection, setActiveSection] = useState('home');
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setActiveSection(sectionId);
    }
  };

  const toggleVideo = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const testimonials = [
    {
      id: 1,
      type: 'text',
      quote: "Paul Vann of IdentifAI delivered an outstanding presentation to our group of executives that was both eye-opening and highly engaging. His ability to tailor complex concepts to fit their professional interests made the session incredibly relevant and impactful. Paul provided a wealth of practical, actionable information, leaving our team with valuable insights which they could immediately implement. His presentation was not only informative but also dynamic, keeping everyone fully engaged throughout. We highly recommend Paul for anyone seeking a knowledgeable and compelling speaker.",
      name: "JD Kathuria",
      title: "CEO, Washington Exec",
      image: "/washexec.jpeg" // Replace with actual image path
    },
    {
      id: 2,
      type: 'text',
      quote: "Paul Vann was a standout speaker at my event during GITEX 2023. The feedback I received was overwhelmingly positive. His passion for cybersecurity, especially for someone so young, truly resonated with the audience. He brought a fresh and unique perspective that made his presentation both engaging and thought-provoking. It was clear that Paul left a lasting impression, and I’m excited to see how his career grows from here. I’d highly recommend him as a speaker for any future events.",
      name: "Prashant Odhrani",
      title: "Conference Producer, Dubai World Trade Centre",
      image: "/gitex.png" // Replace with actual image path
    },
    {
      id: 3,
      type: 'image',
      imageUrl: "/rsac.jpg", // Replace with actual image path
      name: "RSA Conference 2023",
      altText: "RSAC 2023 Top Rated Session",
      caption: "Recognized as a top-rated session for my presentation on ChatGPT, and its dangerous use-cases in the hands of a hacker."
    }
  ];

  const renderTestimonial = (testimonial) => {
    if (testimonial.type === 'text') {
      return (
        <div className="bg-white p-8 rounded-2xl shadow-xl transform hover:-translate-y-2 transition-all duration-300">
          <div className="flex justify-center mb-6">
            <div className="w-20 h-20 bg-gradient-to-br from-purple-400 to-blue-500 rounded-full flex items-center justify-center">
              <img
                src={testimonial.image}
                alt={`${testimonial.name}`}
                className="rounded-full w-18 h-18 border-4 border-white"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "/api/placeholder/80/80";
                }}
              />
            </div>
          </div>
          <p className="text-gray-600 mb-6 text-center italic">"{testimonial.quote}"</p>
          <div className="text-center">
            <p className="font-semibold text-lg">{testimonial.name}</p>
            <p className="text-sm text-gray-500">{testimonial.title}</p>
          </div>
        </div>
      );
    } else if (testimonial.type === 'image') {
      return (
        <div className="bg-white p-8 rounded-2xl shadow-xl transform hover:-translate-y-2 transition-all duration-300">

          <img
            src={testimonial.imageUrl}
            alt={testimonial.altText}
            className="w-full h-48 object-cover mb-6 rounded-lg"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/api/placeholder/400/200";
            }}
          />
          <div className="text-center">
            <p className="font-semibold text-lg">{testimonial.name}</p>
            <p className="text-sm text-gray-500">{testimonial.caption}</p>
          </div>
        </div>
      );
    }
  };


  return (
    <div className="font-['Space_Grotesk',sans-serif] text-gray-900 bg-gray-100">
      {/* Navbar */}
      <nav className="fixed top-0 left-0 right-0 bg-black bg-opacity-80 backdrop-blur-md z-10">
        <div className="container mx-auto px-6 py-4">
          <div className="flex justify-between items-center">
            <div className="text-xl font-bold text-white">Paul Vann</div>
            <div className="hidden md:flex space-x-6">
              {['home', 'about', 'testimonials', 'contact'].map((item) => (
                <button
                  key={item}
                  onClick={() => scrollToSection(item)}
                  className={`capitalize text-sm tracking-wider ${
                    activeSection === item ? 'text-white font-semibold' : 'text-gray-400 hover:text-white transition-colors'
                  }`}
                >
                  {item}
                </button>
              ))}
            </div>
          </div>
        </div>
      </nav>

      {/* Combined Hero and Video Section */}
      <section id="home" className="pt-24 md:pt-40 pb-24 md:pb-32 px-6 bg-gradient-to-br from-purple-900 via-indigo-900 to-blue-900 text-white">
        <div className="container mx-auto">
          <div className="flex flex-col md:flex-row items-center gap-12">
            <div className="w-full md:w-1/2 text-center md:text-left">
              <h1 className="text-4xl md:text-6xl font-bold mb-6 tracking-tight">Paul Vann</h1>
              <p className="text-xl mb-8 font-light tracking-wide">Captivating Speaker | Cybersecurity and AI Expert | Founder</p>
              <div className="flex flex-col md:flex-row gap-4 mb-8">
                <div className="flex items-center justify-center md:justify-start bg-white bg-opacity-10 p-4 rounded-lg">
                  <Calendar className="mr-3 text-blue-300" size={24} />
                  <div>
                    <p className="font-semibold">Next Event</p>
                    <p className="text-sm">BSides Charleston 2024, Nov 2</p>
                  </div>
                </div>
                <div className="flex items-center justify-center md:justify-start bg-white bg-opacity-10 p-4 rounded-lg">
                  <Book className="mr-3 text-purple-300" size={24} />
                  <div>
                    <p className="font-semibold">Latest Research</p>
                    <p className="text-sm">The Future of AI: The Good, The Bad, and The Ugly</p>
                  </div>
                </div>
              </div>
              <button onClick={() => scrollToSection('contact')} className="bg-transparent border-2 border-white text-white px-8 py-3 rounded-full hover:bg-white hover:text-blue-900 transition-all duration-300 transform hover:scale-105">
                Book Now
              </button>
            </div>
            <div className="w-full md:w-1/2 mt-12 md:mt-0">
              <div
                className="relative aspect-video max-w-3xl mx-auto rounded-xl overflow-hidden shadow-2xl"
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
              >
                <video
                  ref={videoRef}
                  className="w-full h-full object-cover"
                  poster="/22.png"
                >
                  <source src="/speaker.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-40">
                  <button onClick={toggleVideo} className="text-white hover:text-blue-300 transition-colors">
                    {isPlaying && isHovering ? (
                      <PauseCircle size={80} />
                    ) : !isPlaying ? (
                      <PlayCircle size={80} />
                    ) : null}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* About Section */}
      <section id="about" className="py-20 px-6">
        <div className="container mx-auto">
          <h2 className="text-4xl font-bold mb-12 text-center">About Me</h2>
          <div className="flex flex-col md:flex-row items-center gap-12">
            <div className="w-full md:w-1/3">
              <div className="relative">
                <div className="absolute inset-0 bg-gradient-to-br from-purple-400 to-blue-500 transform rotate-6 rounded-3xl"></div>
                <img src="paul.jpeg" alt="Speaker" className="relative z-10 rounded-3xl shadow-xl w-full h-auto" />
              </div>
            </div>
            <div className="w-full md:w-2/3">
              <p className="text-xl mb-6 leading-relaxed">
                Paul Vann is a renowned speaker with over 8 years of experience in educating audiences worldwide on the technological changes in our world. Having started his speaking his speaking career at 12 years old, Paul has developed a unique approach combining technical knowledge, a blend of expertise, and practical strategies to provide individuals and organizations with insights into the future of our technological world.
              </p>
              <div className="flex flex-wrap gap-6">
                <div className="flex items-center bg-gray-200 px-4 py-2 rounded-full">
                  <User className="mr-2 text-blue-600" />
                  <span className="font-semibold">15+ Events</span>
                </div>
                <div className="flex items-center bg-gray-200 px-4 py-2 rounded-full">
                  <Building2 className="mr-2 text-purple-600" />
                  <span className="font-semibold">AI Startup Co-founder</span>
                </div>
                <div className="flex items-center bg-gray-200 px-4 py-2 rounded-full">
                  <MessageSquare className="mr-2 text-indigo-600" />
                  <span className="font-semibold">Top Voice in Computer Science</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="testimonials" className="py-20 px-6 bg-gray-100">
        <div className="container mx-auto">
          <h2 className="text-4xl font-bold mb-16 text-center">What People Say</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
            {testimonials.map((testimonial) => (
              <React.Fragment key={testimonial.id}>
                {renderTestimonial(testimonial)}
              </React.Fragment>
            ))}
          </div>
        </div>
      </section>

      {/* Contact Form Section */}
      <section id="contact" className="py-20 px-6 bg-gradient-to-br from-purple-900 via-indigo-900 to-blue-900 text-white">
        <div className="container mx-auto max-w-2xl">
          <h2 className="text-4xl font-bold mb-12 text-center">Get in Touch</h2>
          <form action="https://formspree.io/f/mvgpedyl" method="POST" className="bg-white bg-opacity-10 backdrop-blur-lg p-10 rounded-2xl shadow-xl">
            <div className="mb-6">
              <label htmlFor="name" className="block text-white font-semibold mb-2">Name</label>
              <input name="name" type="text" id="name" className="w-full px-4 py-3 bg-white bg-opacity-20 border border-white border-opacity-30 rounded-lg focus:outline-none focus:ring-2 focus:ring-white focus:bg-opacity-30 transition-all duration-300" />
            </div>
            <div className="mb-6">
              <label htmlFor="email" className="block text-white font-semibold mb-2">Email</label>
              <input name="email" type="email" id="email" className="w-full px-4 py-3 bg-white bg-opacity-20 border border-white border-opacity-30 rounded-lg focus:outline-none focus:ring-2 focus:ring-white focus:bg-opacity-30 transition-all duration-300" />
            </div>
            <div className="mb-6">
              <label htmlFor="message" className="block text-white font-semibold mb-2">Message</label>
              <textarea name="message" id="message" rows="4" className="w-full px-4 py-3 bg-white bg-opacity-20 border border-white border-opacity-30 rounded-lg focus:outline-none focus:ring-2 focus:ring-white focus:bg-opacity-30 transition-all duration-300"></textarea>
            </div>
            <button type="submit" className="w-full bg-white text-blue-900 py-3 px-6 rounded-lg font-semibold hover:bg-opacity-90 transition-all duration-300 transform hover:scale-105">
              Send Message
            </button>
          </form>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-black text-white py-8 px-6">
        <div className="container mx-auto text-center">
          <p>&copy; 2024 Paul Vann. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default SpeakerProfile;
